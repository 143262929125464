<template>
  <div :class="{'monitor-view-wrapper': isMonitorView}" id="monitor-view">
    <div class="mt-4 px-5">

      <h2>Heute</h2>

      <div :class="{'monitor-view': isMonitorView, 'normal-view': !isMonitorView}">
        <v-data-table
            v-if="show_SL"
            :class="{'large-table': isMonitorView}"
            :headers="comp_Table_Header_SL"
            :items="comp_TodaySL"
            class="elevation-1 my-2"
            ref="TodaySL"
            no-data-text="Kein Schiessleiter eingebucht"
            hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat color="warning">
              <h3>Schiessleiter</h3>
              <v-spacer></v-spacer>
              <v-btn icon @click="getTodaySL">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.beginn="{ item }">
        <span>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
          }}</span>
          </template>

          <template v-slot:item.beenden="{ item }">
            <v-btn
                class="error my-1"
                text @click="ende_other_aufsicht(item)">
              <v-icon>
                mdi-clock-end
              </v-icon>
            </v-btn>
          </template>

        </v-data-table>

        <v-data-table
            v-if="show_SA"
            :class="{'large-table': isMonitorView}"
            :headers="comp_Table_Header_SA"
            :items="comp_TodaySA"
            class="elevation-1 my-2"
            ref="TodaySA"
            no-data-text="Kein Standaufsicht eingebucht, eingeschränkter Schiessbetrieb!"
            hide-default-footer
            :item-class="itemRowClass"
        >
          <template v-slot:top>
            <v-toolbar flat color="info">
              <h3>Standaufsicht</h3>
              <v-spacer></v-spacer>
              <v-btn icon @click="getTodaySA">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.beginn="{ item }">
        <span>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
          }}</span>
          </template>

          <template v-slot:item.beenden="{ item }">
            <v-btn
                class="error my-1"
                text @click="ende_other_aufsicht(item)">
              <v-icon>
                mdi-clock-end
              </v-icon>
            </v-btn>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.allein_aufsicht="{ item }">
            <v-icon
                v-if="!item.allein_aufsicht"
                color="success">
              mdi-check
            </v-icon>
            <v-icon
                v-if="item.allein_aufsicht"
                color="red">
              mdi-window-close
            </v-icon>
          </template>

        </v-data-table>
      </div>

      <v-data-table
          v-if="show_user"
          :headers="headers"
          :items="comp_TodayMember"
          sort-by="KurzBez"
          group-by="KurzBez"
          class="elevation-1 my-2"
          ref="TodayMember"
          no-data-text="Kein Schütze eingebucht"
      >
        <template v-slot:top>
          <v-toolbar flat color="success">
            <h3>Schützen</h3>
            <v-spacer></v-spacer>
            <v-btn icon @click="getTodayMember">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:item.beginn="{ item }">
        <span>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
          }}</span>
        </template>

      </v-data-table>

      <div v-if="isMonitorView" class="clock-container">
        <div class="clock-overlay">
          {{ currentTime }}
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import client, {initializeAblyClient} from "@/plugins/ably";

export default {
  name: 'today',
  data: () => ({
    polling: null,
    dialog: false,
    dialogDelete: false,
    loading: false,
    show_SL: true,
    show_SA: true,
    show_user: true,
    channel: null,
    channel2: null,
    range_id_param: null,
    isMonitorView: false,
    shouldReconnect: true,
    currentTime: '',
    headers_Table_Action: [
      {text: 'Aufsicht Regulär', align: 'center', value: 'allein_aufsicht', width: "100px"},
      {text: 'Beenden', value: 'beenden', align: 'right', width: "250px"}
    ],
    headers: [
      {text: 'Name', value: 'name', align: 'left', width: "250px", sortable: false},
      {text: 'Vorname', value: 'vorname', align: 'left', width: "250px"},
      {text: 'Begin', value: 'BeginFormat', align: 'left', width: "250px"},
      {text: 'Stand', value: 'KurzBez', align: 'left', width: "250px"},
      {text: 'Bahn', value: 'bahn', align: 'left', width: "250px"},
      // {text: "", value: "platzhalter1", align: 'left', width: "250px"},

    ],
    headers_SA: [
      {text: 'Name', value: 'name', align: 'left', sortable: false, width: "250px"},
      {text: 'Vorname', value: 'vorname', align: 'left', width: "250px"},
      {text: 'Begin', value: 'BeginFormat', align: 'left', width: "250px"},
      {text: 'Stand', value: 'KurzBez', align: 'left', width: "250px"}
    ],
    headers_SL: [
      {text: 'Name', value: 'name', align: 'left', sortable: false, width: "250px"},
      {text: 'Vorname', value: 'vorname', align: 'left', width: "250px"},
      {text: 'Begin', value: 'BeginFormat', align: 'left', width: "250px"},
      // {text: "", value: "platzhalter1", align: 'left', width: "250px"},
      // {text: "", value: "platzhalter1", align: 'left', width: "250px"}

    ],
  }),

  computed: {
    comp_TodayMember() {
      return this.$store.state.ClubApp.TodayMember;
    },
    comp_TodaySA() {
      const SAList = this.$store.state.ClubApp.TodaySA;
      if (this.range_id_param) {
        return SAList.filter(sa => this.range_id_param.includes(sa.shootingrange_id));
      }
      return SAList;
    },
    comp_TodaySL() {
      return this.$store.state.ClubApp.TodaySL;
    },
    isClubLoggedIn() {
      return this.$store.getters["ClubAuth/isClubLoggedIn"];
    },
    isClubID() {
      return this.$store.getters["ClubAuth/isClubID"];
    },
    comp_Table_Header_SA() {
      let header_SA = [...this.headers_SA];
      if (!this.isMonitorView) {
        header_SA = [...this.headers_SA, ...this.headers_Table_Action];
      }
      return header_SA;
    },
    comp_Table_Header_SL() {
      let header_SL = [...this.headers_SL];
      if (!this.isMonitorView) {
        header_SL = [...this.headers_SL, ...this.headers_Table_Action];
      }
      return header_SL;
    },
  },

  async created() {
    this.shouldReconnect = true;
    const params = this.$route.params;
    if (Object.keys(params).length === 0) {
      this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', true);
      await this.getTodaySA();
      await this.getTodaySL();
      await this.getTodayMember();
      await this.start_websocket();
    } else {
      this.isMonitorView = true;
      await this.loginwithToken();
      this.updateTime();
      this.applyMonitorViewStyles();
    }
  },
  beforeDestroy() {
    this.shouldReconnect = false;
    if (this.channel) this.channel.unsubscribe();
    if (this.channel2) this.channel2.unsubscribe();
    if (client) {
      client.close();
      this.$store.dispatch('GeneralApp/Websocket_state', false);
    }
    if (this.isMonitorView) {
      this.removeMonitorViewStyles();
    }
  },
  methods: {
    async start_websocket() {
      await initializeAblyClient();

      let reconnectAttempts = 0;

      if (!this.shouldReconnect) {
        console.log("Seite verlassen, reconnect wird abgebrochen.");
        return;
      }


      // Verbindung überwachen und Reconnect auslösen
      await client.connection.on((stateChange) => {
        console.log("Verbindungsstatus: ", stateChange.current);

        if (stateChange.current === 'connected' && !this.channel && !this.channel2) {
          console.log("Verbunden und noch nicht abonniert.");
          reconnectAttempts = 0; // Rücksetzen

          this.channel = client.channels.get('private:clubs.' + this.isClubID + '.ClubTodayChannel');
          this.channel2 = client.channels.get('clubs/' + this.isClubID + '/range_safty');

          this.channel.subscribe((message) => {
            // Sicherstellen, dass message und seine Struktur gültig sind
            if (message?.data?.message) {
              const {Today, TodaySA, TodaySL} = message.data.message;
              if (TodaySA && TodaySL && Today) {
                // Vuex-Store updaten
                this.$store.commit('ClubApp/get_TodaySA', TodaySA);
                this.$store.commit('ClubApp/get_TodaySL', TodaySL);
                this.$store.commit('ClubApp/get_TodayMember', Today);
              } else {
                console.error("Ungültige Datenstruktur:", message.data);
              }
            } else {
              console.error("Nachricht nicht lesbar oder ungültig:", message);
            }
          });

          this.channel2.subscribe((msg) => {
            const jsonString = new TextDecoder().decode(msg.data);
            try {
              const data = JSON.parse(jsonString);
              console.log(data);
            } catch (e) {
              console.error("Konnte den Nachrichteninhalt nicht als JSON parsen", e);
            }
          });

          this.$store.dispatch('GeneralApp/Websocket_state', true);
        }

        if (stateChange.current === 'disconnected' || stateChange.current === 'suspended') {
          console.warn("Ably client ist getrennt, erneuter Verbindungsaufbau...");
          this.$store.dispatch('GeneralApp/Websocket_state', false);

          // Rückoff-Mechanismus: Verzögere jeden erneuten Verbindungsversuch
          const delay = Math.min(30000, 1000 * Math.pow(2, reconnectAttempts)); // Maximal 30 Sekunden
          reconnectAttempts++;

          setTimeout(() => {
            client.close(); // Verbindung vollständig schließen
            this.start_websocket();
          }, delay);
        }

        if (stateChange.current === 'failed') {
          console.error("Verbindung fehlgeschlagen. Bitte prüfen Sie Ihre Netzwerkverbindung.");
          this.$store.dispatch('GeneralApp/Websocket_state', false);
          client.close();
          this.start_websocket(); // WebSocket erneut starten
        }

        if (stateChange.current === 'closed') {
          console.error("Ably Verbindung closed.");
          this.$store.dispatch('GeneralApp/Websocket_state', false);

          // Rückoff-Mechanismus: Verzögere jeden erneuten Verbindungsversuch
          if (this.shouldReconnect) {
            const delay = Math.min(30000, 1000 * Math.pow(2, reconnectAttempts)); // Maximal 30 Sekunden
            reconnectAttempts++;

            setTimeout(() => {
              client.close(); // Verbindung vollständig schließen
              this.start_websocket();
            }, delay);
          }
        }
      });


    },
    updateTime() {
      setInterval(() => {
        const now = new Date();
        this.currentTime = now.toLocaleTimeString(['de-DE'], {hour: '2-digit', minute: '2-digit'});
      }, 1000);
    },
    getTodayMember() {
      if (this.show_user) {
        this.$store.dispatch('ClubApp/getTodayMember');
      }
    },
    getTodaySA() {
      if (this.show_SA) {
        this.$store.dispatch('ClubApp/getTodaySA');
      }
    },
    getTodaySL() {
      if (this.show_SL) {
        this.$store.dispatch('ClubApp/getTodaySL');
      }
    },
    ende_other_aufsicht(item) {
      const Data = {
        shootingbook_id: item.id
      }

      this.$store.dispatch('ClubApp/end_other_aufsicht', Data)

    },

    itemRowClass(item) {
      var Class = null;
      if (item.allein_aufsicht === 1) {
        Class = 'blue lighten-4';
      } else if (item.allein_aufsicht === 0) {
        Class = '';
      }
      return Class;
    },

    async loginwithToken() {
      const params = this.$route.params;
      const token = params.token;
      const clubid = params.clubid;

      // '/club/today_monitor/:clubid/:token/:rangeid?' /14,15,20
      if (params.rangeid) {
        this.range_id_param = params.rangeid.split(',').map(Number);
      } else {
        this.range_id_param = null;
      }

      const data = {
        clubid: clubid,
        token: token
      };

      this.show_user = false;
      this.show_SL = false;
      this.show_SA = true;

      await this.$store.dispatch('ClubAuth/login', data)
          .then(() =>
              this.$store.commit('GeneralApp/SET_SHOW_NAVIGATION', false)
          )
          .catch(err => console.log(err))
          .finally(this.start_websocket)
          .finally(this.getTodaySA)
          .finally(this.getTodayMember)
          .finally(this.getTodaySL)
          .finally(this.applyMonitorViewStyles);
    },
    applyMonitorViewStyles() {
      document.documentElement.classList.add('monitor-view');
      document.body.classList.add('monitor-view');
    },
    removeMonitorViewStyles() {
      document.documentElement.classList.remove('monitor-view');
      document.body.classList.remove('monitor-view');
    }
  },
}
</script>

<style>
@media (min-width: 1024px) {
  .monitor-view {
    font-size: 1.1rem;
  }

  /* Nur für die .large-table */
  .large-table .v-data-table-header th {
    font-size: 0.8rem; /*!important; */ /* Anpassung des Tabellen-Headers */
    padding: 9px /*!important; */
  }

  .large-table .v-data-table__wrapper td {
    font-size: 1rem; /* !important; */ /* Anpassung des Tabellen-Bodys */
    padding: 8px; /*!important; */
    line-height: 1.5rem /*!important; */
  }
}

/* Stile für den Monitor-View */
html.monitor-view,
body.monitor-view {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

html.monitor-view .btn_logout {
  display: none !important; /* Button ausblenden */
}

.clock-container {
  position: relative;
}

.clock-overlay {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  font-size: 3rem;
  z-index: 1000;
  opacity: 0.8;
}

.scroll-sync {
  display: flex;
  overflow-x: auto;
}

.table-container {
  min-width: 33%; /* Sorgt dafür, dass jede Tabelle denselben Platz bekommt */
  overflow: auto;
}
</style>